.App {
    text-align: center;
}

*:focus {
    outline: none;
}

a {
    color: #818178;
}

a:hover {
    color: #4bac28
}

html, body, #root {
    height: 100%;
}
