.overlayWrong {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    background-color: rgb(219, 42, 27);
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    animation-name: fadeOutWrong;
    animation-duration: 1.3s;
    animation-delay: 0.4s;

    /*New content */
    -webkit-animation-fill-mode: forwards;
}

.overlayRight {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    background-color: rgb(32, 166, 89);
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    animation-name: fadeOutRight;
    animation-duration: 1.3s;
    animation-delay: 0.4s;
    /*New content */
    -webkit-animation-fill-mode: forwards;
}

.iconAnimation {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    z-index: 3; /* Specify a stack order in case you're using a different order for other elements */
    animation-name: zoom;
    animation-duration: 1.2s;
    /*New content */
    -webkit-animation-fill-mode: forwards;

}


.AnswerAnimation {

    -webkit-animation: tracking-in-expand-fwd-top 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: tracking-in-expand-fwd-top 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation-delay: 0.4s;
}

.puff-in-center {
    -webkit-animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
    animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
    animation-delay: 0.4s;

}

.slide-top {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation-delay: 1.4s;

}

.bounce-in-bck {
    -webkit-animation: bounce-in-bck 1.1s both;
    animation: bounce-in-bck 1.1s both;
}


@-webkit-keyframes bounce-in-bck {
    0% {
        -webkit-transform: scale(3);
        transform: scale(3);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }
    38% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }
    55% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    72% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    81% {
        -webkit-transform: scale(1.24);
        transform: scale(1.24);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    89% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    95% {
        -webkit-transform: scale(1.04);
        transform: scale(1.04);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes bounce-in-bck {
    0% {
        -webkit-transform: scale(3);
        transform: scale(3);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }
    38% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }
    55% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    72% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    81% {
        -webkit-transform: scale(1.24);
        transform: scale(1.24);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    89% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    95% {
        -webkit-transform: scale(1.04);
        transform: scale(1.04);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}


@-webkit-keyframes tracking-in-expand-fwd-top {
    0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        -webkit-transform: translateY(200px);
        transform: translateY(200px);
        opacity: 1;

    }
}

@keyframes tracking-in-expand-fwd-top {
    0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1;
    }
}


/* The animation code */
@keyframes fadeOutWrong {
    from {
        background-color: rgb(219, 42, 27);
    }
    to {
        background-color: transparent;
        visibility: hidden;
    }
}



/* The animation code */
@keyframes fadeOutRight {
    from {
        background-color: rgb(32, 166, 89);
    }
    to {
        background-color: transparent;
        visibility: hidden;
    }
}

/* The animation code */
@keyframes zoom {
    0% {
        transform: scale(2.0);
    }
    100% {
        transform: scale(1.0);
        visibility: hidden;
    }
}


@-webkit-keyframes puff-in-center {
    0% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

@keyframes puff-in-center {
    0% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-top {
    0% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
